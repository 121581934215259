import { render, staticRenderFns } from "./RootAccessValidate.vue?vue&type=template&id=72da2499&scoped=true"
import script from "./RootAccessValidate.vue?vue&type=script&lang=ts"
export * from "./RootAccessValidate.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72da2499",
  null
  
)

export default component.exports