<template>
		<v-row class="email-login login" elevation-2>
            <v-col cols="12">
				<v-card-text class="pa-2">
                <vops-form @submit="mfaLogin()">
                    <div class="mt-3 mb-3">
                        {{ $t($tl.multiFactorAuthentication.totpCode) }}
                    </div>
                    <v-text-field v-model="mfaCode"
                                  light
                                  v-focus
                                  @input="clearServerErrors()"
                                  :rules="[$rules.required, mfaCodeErrors]"  />
                    <v-btn dark block color="#4146E0" type="submit" :loading="isLoading">{{$t($tl.signIn)}}</v-btn>
                </vops-form></v-card-text>
            </v-col>
		</v-row>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import {AuthenticationState, LoginResponseModel, MfaChallengeModel} from "@/models/generated";
import {AuthService} from "@/services/generated";
import CenterView from "@/components/CenterView.vue";

@Component({
    components: {CenterView}
})
export default class MfaLogin extends Vue{
    @Prop({required: true}) sessionId: string;
    @Prop({ type: String, required: true }) email: string;

    isLoading: boolean = false;
    mfaCode: string = "";
    isError: boolean = false;

    get mfaCodeErrors() {
        return !this.isError || this.$t(this.$tl.multiFactorAuthentication.invalidMfaCode);
    }

    clearServerErrors() {
        this.isError = false;
    }

    async mfaLogin() {
        this.isLoading = true;

        try {
            this.mfaCode = this.mfaCode.replace(" ", "");
            const mfaChallengeModel: MfaChallengeModel = { sessionId: this.sessionId, code: this.mfaCode, email: this.email };
            const loginResponseModel = await AuthService.respondToMfaChallenge(mfaChallengeModel);
            await this.determineLoginState(loginResponseModel);
        } catch {
            this.isError = true
            this.isLoading = false;
        }
    }

    async determineLoginState(loginResponseModel: LoginResponseModel) {
        if (loginResponseModel.authenticationState == AuthenticationState.Authenticated) {
            window.location.assign(loginResponseModel.returnUrl);
        } else if (loginResponseModel.authenticationState == AuthenticationState.NeedsPasswordReset) {
            await this.$router.push({ path: '/resetpassword?email=' + encodeURIComponent(this.email) });
        } else {
            this.isError = true;
        }
    }
}
</script>