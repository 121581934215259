<template>
		<v-row v-if="!emailSent" class="login">
			<v-col cols="12">
				<vops-form @submit="requestReset()">
					<v-card-text class="pa-2"><v-text-field :label="$t($tl.email)"
								  v-model="email"
								  v-focus light
								  :error-messages="initiateForgotPasswordErrors"
								  :rules="[$rules.required, $rules.email]"
								  prepend-inner-icon="keyboard_backspace"
								  @click:prepend-inner="goBack"
								  @blur="clearServerErrors"
								  @input="clearServerErrors"/>
					</v-card-text>
					<v-btn block dark color="#4146E0" type="submit" :loading="isLoading">{{ $t($tl.sendEmail) }}</v-btn>
				</vops-form>
			</v-col>
		</v-row>
		<v-row class="forgotPassword-resetPassword login" v-else>
			<v-col cols="12">
				<div class="mt-3 mb-3">
					{{ $t($tl.passwordMessages.forgotPasswordCheckEmail) }}
				</div>
				<vops-form @submit="resetPassword()">
					<v-text-field :label="$t($tl.email)"
								  light
								  v-model="email"
								  :error-messages="resetPasswordErrors"
								  @blur="clearServerErrors"
								  readonly="readonly"/>
					<v-text-field :label=" $t($tl.verificationCode)"
								  light v-model="verificationCode"
								  v-focus
								  validate-on-blur
								  type="password"
								  :error-messages="codeErrors"
								  :rules="[$rules.required]"
								  @blur="clearServerErrors"
								  @input="clearServerErrors"/>
					<v-text-field class="forgotPassword-multiline"
								  :label="$t($tl.newPassword)"
								  light v-model="newPassword"
								  type="password"
								  validate-on-blur
								  :rules="[$rules.required, newPasswordRules]"
								  @blur="clearServerErrors"
								  @input="clearServerErrors"/>
					<v-text-field :label="$t($tl.confirmPassword)"
								  light v-model="confirmPassword"
								  type="password"
								  validate-on-blur
								  :rules="[$rules.required, passwordsMatchRule]"
								  @blur="clearServerErrors"
								  @input="clearServerErrors"/>
					<v-btn block dark color="#4146e0"
						   type="submit"
						   :loading="isLoading">
						{{ $t($tl.resetPassword) }}
					</v-btn>
				</vops-form>
			</v-col>
		</v-row>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {PasswordService} from '@/services/generated';
import CenterView from './CenterView.vue';
import {PasswordPolicyModel} from "../../../../VenueOps.Accounts.Support/clientapp/src/models/generated";
import {ResetForgottenPasswordModel} from "@/models/generated";
import {hasLowerCase, hasNumber, hasSpecialCharacters, hasUpperCase, hasWhitespace} from "@/services/validation";

@Component({
	components: {
		CenterView
	}
})
export default class ForgotPassword extends Vue {
	email: string = null;
	isLoading: boolean = false;
	isInvalidUser: boolean = false;
	emailSent: boolean = false;
	newPassword: string = "";
	confirmPassword: string = "";
	verificationCode: string = "";
	isInvalidCode: boolean = false;
	invalidReset: boolean = false;
	passwordAttemptsExceeded: boolean = false;
	initiateAttemptsExceeded: boolean = false;
	sessionId: string = null;
	passwordPolicy: PasswordPolicyModel = null;

	mounted() {
		this.email = this.$route.params.email;
	}

	get codeErrors() {
		return this.isInvalidCode ? [this.$t(this.$tl.passwordMessages.invalidVerificationCode)] : [];
	}

	get resetPasswordErrors() {
		let errorMessage = [];
		if (this.passwordAttemptsExceeded) {
			errorMessage = [this.$t(this.$tl.passwordMessages.passwordAttemptsReachedMessage)];
		} else if (this.invalidReset) {
			errorMessage = [this.$t(this.$tl.passwordMessages.problemResetting)];
		}
		return errorMessage;
	}

	get initiateForgotPasswordErrors() {
		let errorMessage = [];
		if (this.isInvalidUser) {
			errorMessage = [this.$t(this.$tl.emailMessages.noUser)];
		} else if (this.initiateAttemptsExceeded) {
			errorMessage = [this.$t(this.$tl.passwordMessages.passwordAttemptsReachedMessage)];
		}
		return errorMessage;
	}

	get newPasswordRules() {
		let errorMessage = "";
		if (this.passwordPolicy != null) {
			if (this.newPassword.length < this.passwordPolicy.minimumLength) {
				errorMessage += `${this.$t(this.$tl.passwordLengthValue, {value: this.passwordPolicy.minimumLength})}\n`;
			}
			if (this.passwordPolicy.requireLowercase && !hasLowerCase(this.newPassword)) {
				errorMessage += `${this.$t(this.$tl.passwordMessages.lowercaseRequired)}\n`;
			}
			if (this.passwordPolicy.requireUppercase && !hasUpperCase(this.newPassword)) {
				errorMessage += `${this.$t(this.$tl.passwordMessages.uppercaseRequired)}\n`;
			}
			if (this.passwordPolicy.requireNumbers && !hasNumber(this.newPassword)) {
				errorMessage += `${this.$t(this.$tl.passwordMessages.numbersRequired)}\n`;
			}
			if (this.passwordPolicy.requireSymbols && !hasSpecialCharacters(this.newPassword)) {
				errorMessage += `${this.$t(this.$tl.passwordMessages.specialCharactersRequired)}\n`;
			}
		}
		if (hasWhitespace(this.newPassword)) {
			errorMessage += `${this.$t(this.$tl.passwordMessages.whitespaceDetected)}\n`;
		}
		return !errorMessage || errorMessage.trim();
	}

	get passwordsMatchRule() {
		let errorMessage = "";

		if (this.newPassword !== this.confirmPassword) {
			errorMessage = `${this.$t(this.$tl.passwordMessages.dontMatch)}`
		}

		return !errorMessage || errorMessage; 
	}

	clearServerErrors() {
		this.passwordAttemptsExceeded = false;
		this.initiateAttemptsExceeded = false;
		this.isInvalidUser = false;
		this.invalidReset = false;
		this.isInvalidCode = false;
	}

	async goBack() {
		await this.$router.push({name: 'login'});
	}

	async requestReset() {
		this.isLoading = true;
		try {
			const response = await PasswordService.forgotPassword({email: this.email});
			this.emailSent = true;
			this.passwordPolicy = response.passwordPolicy;
		} catch (ex: any) {
			if (ex.data.passwordAttemptsExceeded) {
				this.initiateAttemptsExceeded = true;
			} else {
				this.isInvalidUser = true;
			}
		} finally {
			this.isLoading = false;
		}
	}

	async resetPassword() {
		try {
			this.isLoading = true;

			const request: ResetForgottenPasswordModel = {
				email: this.email,
				confirmationCode: this.verificationCode.replace(" ", ""),
				newPassword: this.newPassword
			};
			await PasswordService.resetForgottenPassword(request);
			await this.$router.push({path: `/?email=${encodeURIComponent(this.email)}&reset=true`})

		} catch (ex: any) {
			if (ex.data.incorrectVerificationCode) {
				this.isInvalidCode = true;
			} else if (ex.data.attemptsExceeded) {
				this.passwordAttemptsExceeded = true;
			} else {
				this.invalidReset = true;
			}
		} finally {
			this.isLoading = false;
		}
	}
}
</script>
<style lang="scss">
.forgotPassword-multiline {
	white-space: pre-line;
}

.forgotPassword-resetPassword {
	animation-name: fade-in;
	animation-duration: .5s;
}
</style>