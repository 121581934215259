<template>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { AuthService } from '../services/generated';

@Component
export default class Impersonate extends Vue {
	async mounted() {
		const userId = this.$route.params.userId;
		const url = await AuthService.impersonate(userId);

		window.location.assign(url);
	}
}
</script>