import Axios, { AxiosError } from "axios";

export default class AxiosConfig {
	static bootstrap() {
		Axios.interceptors.request.use(config => {
			const vopsClient = process.env.VUE_APP_VERSION;
			config.headers["Vops-Client"] = vopsClient;
			return config;
		});

		Axios.interceptors.response.use(response => response, (error: AxiosError) => {
			if (error.response.status === 409)
				window.location.reload();
			return Promise.reject(error.response);
		});
	}
}