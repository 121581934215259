<template>
  
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";

@Component
export default class EbAccess extends Vue {
	mounted() {
		window.location.assign("/rootaccess");
	}
}
</script>

<style scoped>

</style>