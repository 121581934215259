import Vue from 'vue';
import Router from 'vue-router';
import NotFound from '@/components/NotFound.vue';
import ForgotPassword from '@/components/ForgotPassword.vue';
import Impersonate from '@/components/Impersonate.vue';
import Deimpersonate from '@/components/Deimpersonate.vue';
import Logout from '@/components/Logout.vue';
import RootAccess from '@/components/RootAccess.vue';
import EmailLogin from "@/components/EmailLogin.vue";
import ValidateSso from "@/components/ValidateSso.vue";
import TemporaryPassword from "@/components/TemporaryPassword.vue";
import RootAccessValidate from "@/components/RootAccessValidate.vue";
import EbAccess from "@/components/EbAccess.vue";
import CenterView from "@/components/CenterView.vue";

Vue.use(Router);

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{name: 'email', path: '/email', component: CenterView, children: [
				{name: 'login', path: '/login', component: EmailLogin},
				{name: 'forgotpassword', path: '/forgotpassword', component: ForgotPassword},
				{name: 'resetpassword', path: '/resetpassword', component: TemporaryPassword},
				{name: 'logout', path: '/logout', alias: '/', component: Logout},
				{name: 'impersonate', path: '/impersonate/:userId?', component: Impersonate},
				{name: 'deimpersonate', path: '/deimpersonate', component: Deimpersonate},
				{name: 'ebaccess', path: '/ebaccess', component: EbAccess},
				{name: 'rootaccess', path: '/rootaccess', component: RootAccess},
				{name: 'validate', path: '/validate/:id?', component: ValidateSso},
				{name: 'validaterootaccess', path: '/rootaccess/validate', component: RootAccessValidate},
				{name: 'notfound', path: '*', component: NotFound}
			]},
		{
			path :"/",
			redirect: () => {
				return {name:"login"}
			}
		}
	],
});
