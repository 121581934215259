<template>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import CenterView from "@/components/CenterView.vue";
import {RootAuthService} from "@/services/generated";

@Component({components: {CenterView}})
export default class RootAccess extends Vue {
	loginUrl: string = null;
	
	async created() {
		this.loginUrl = await RootAuthService.getFederatedLoginUrl();
		window.location.assign(this.loginUrl);
	}
}
</script>