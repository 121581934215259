<template>
			<vops-form @submit="resetPassword()">
				<v-text-field
					:label="$t($tl.email)"
					light
					v-model="email"
					:error-messages="problemResettingErrors"
					@blur="clearServerErrors"/>
				<v-text-field
					class="resetPassword-multiline"
					:label="$t($tl.newPassword)"
					light v-model="newPassword"
					v-focus
					validate-on-blur
					type="password"
					:rules="[$rules.required, newPasswordRules]"
					@blur="clearServerErrors"
					@input="clearServerErrors"/>
				<v-text-field
					:label="$t($tl.confirmPassword)"
					light v-model="confirmPassword"
					type="password"
					validate-on-blur
					:rules="[$rules.required, passwordsMatchRule]"
					@blur="clearServerErrors"
					@input="clearServerErrors"/>
				<v-btn block dark color="#4146E0"
					   type="submit"
					   :loading="isLoading">
					{{ $t($tl.resetPassword) }}
				</v-btn>
			</vops-form>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import {
	SetNewPasswordModel,
	SetNewPasswordResponseModel, VerifyTemporaryPasswordResponseModel
} from "@/models/generated";
import {PasswordService} from '@/services/generated';
import CenterView from './CenterView.vue';
import {hasLowerCase, hasNumber, hasSpecialCharacters, hasUpperCase, hasWhitespace} from "@/services/validation";
import {PasswordPolicyModel} from "../../../../VenueOps.Accounts.Support/clientapp/src/models/generated";

@Component({
	components: {
		CenterView
	}
})
export default class ResetPassword extends Vue {
	@Prop() verifyTemporaryPasswordResponseModel: VerifyTemporaryPasswordResponseModel
	@Prop() userEmail: string;

	email: string = ""
	newPassword: string = "";
	confirmPassword: string = "";
	temporaryPassword: string = "";
	isInvalidReset: boolean = false;
	passwordAttemptsExceeded: boolean = false;
	isLoading: boolean = false;
	sessionExpired: boolean = false;
	sessionId: string = null;
	passwordPolicy: PasswordPolicyModel = null;

	created() {
		this.email = this.userEmail;
		this.passwordPolicy = this.verifyTemporaryPasswordResponseModel.passwordPolicy;
		this.sessionId = this.verifyTemporaryPasswordResponseModel.sessionId;
	}

	get problemResettingErrors() {
		let errorMessage = [];
		if (this.isInvalidReset) {
			errorMessage = [this.$t(this.$tl.passwordMessages.problemResetting)];
		} else if (this.sessionExpired) {
			errorMessage = [this.$t(this.$tl.passwordMessages.sessionExpired)];
		}
		return errorMessage;
	}

	get newPasswordRules() {
		let errorMessage = "";

		if (this.newPassword.length < this.passwordPolicy.minimumLength) {
			errorMessage += `${this.$t(this.$tl.passwordLengthValue, {value: this.passwordPolicy.minimumLength})}\n`;
		}
		if (this.passwordPolicy.requireLowercase && !hasLowerCase(this.newPassword)) {
			errorMessage += `${this.$t(this.$tl.passwordMessages.lowercaseRequired)}\n`;
		}
		if (this.passwordPolicy.requireUppercase && !hasUpperCase(this.newPassword)) {
			errorMessage += `${this.$t(this.$tl.passwordMessages.uppercaseRequired)}\n`;
		}
		if (this.passwordPolicy.requireNumbers && !hasNumber(this.newPassword)) {
			errorMessage += `${this.$t(this.$tl.passwordMessages.numbersRequired)}\n`;
		}
		if (this.passwordPolicy.requireSymbols && !hasSpecialCharacters(this.newPassword)) {
			errorMessage += `${this.$t(this.$tl.passwordMessages.specialCharactersRequired)}\n`;
		}
		if (hasWhitespace(this.newPassword)) {
			errorMessage += `${this.$t(this.$tl.passwordMessages.whitespaceDetected)}\n`;
		}

		return !errorMessage || errorMessage.trim();
	}

	get passwordsMatchRule() {
		let errorMessage = "";

		if (this.newPassword !== this.confirmPassword) {
			errorMessage = `${this.$t(this.$tl.passwordMessages.dontMatch)}`
		}
		return !errorMessage || errorMessage;
	}

	clearServerErrors() {
		this.isInvalidReset = false;
		this.sessionExpired = false;
		this.passwordAttemptsExceeded = false;
	}

	async resetPassword() {
		try {
			this.isLoading = true;
			const setNewPasswordModel: SetNewPasswordModel = {
				email: this.email,
				sessionId: this.sessionId,
				newPassword: this.newPassword
			}
			const response: SetNewPasswordResponseModel = await PasswordService.setNewPassword(setNewPasswordModel);
			await this.determineResult(response);
		} catch (ex: any) {
			if (ex.data.sessionExpired) {
				this.sessionExpired = true;
			} else {
				this.isInvalidReset = true;
			}
		} finally {
			this.isLoading = false;
		}
	}

	async determineResult(response: SetNewPasswordResponseModel) {
		if (response.returnUrl) {
			window.location.assign(response.returnUrl);
		} else {
			this.isInvalidReset = true;
		}
	}
}
</script>
<style lang="scss">
.resetPassword-multiline {
	white-space: pre-line;
}

.resetPassword {
	animation-name: slide-in;
	animation-duration: .5s;
}
</style>