import axios, { AxiosRequestConfig } from 'axios';
import { ValidateAuthorizationCodeResponseModel } from '../../models/generated/validateAuthorizationCodeResponseModel';
import { ValidateAuthorizationCodeModel } from '../../models/generated/validateAuthorizationCodeModel';

export const SsoAuthUrls = {
    validateAuthorizationCode: '/api/SsoAuth/validateAuthorizationCode'
};

export class SsoAuthService {
    public static validateAuthorizationCode(model: ValidateAuthorizationCodeModel, config: AxiosRequestConfig = {}): Promise<ValidateAuthorizationCodeResponseModel> {
        config = {
            ...config,
        };
        const _data = model;
        return axios.post<ValidateAuthorizationCodeResponseModel>(SsoAuthUrls.validateAuthorizationCode, _data, config).then(x => x.data);
    }
    
}