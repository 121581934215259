import { AuthService } from './generated';

export default class AuthRedirectService {
	static async RedirectIfAuthenticated() {
		const isAuthenticated = await AuthService.isAuthenticated();

		if (isAuthenticated) {
			const url = await AuthService.getLandingPage();
			window.location.assign(url);
		}

		return isAuthenticated;
	}
}