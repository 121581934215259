<template>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { AuthService } from '../services/generated';

@Component
export default class Deimpersonate extends Vue {
	async mounted() {
		const url = await AuthService.deimpersonate();
		window.location.assign(url);
	}
}
</script>