<template>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { AuthService } from '@/services/generated';

@Component
export default class Logout extends Vue {
	async mounted() {
		await AuthService.logout();
		this.$router.push({name: 'email'});
	}
}
</script>