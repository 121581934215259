import { render, staticRenderFns } from "./RootAccess.vue?vue&type=template&id=33e21cb0"
import script from "./RootAccess.vue?vue&type=script&lang=ts"
export * from "./RootAccess.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports