import axios, { AxiosRequestConfig } from 'axios';
import { ValidateAuthorizationCodeResponseModel } from '../../models/generated/validateAuthorizationCodeResponseModel';
import { RootLoginModel } from '../../models/generated/rootLoginModel';

export const RootAuthUrls = {
    login: '/api/RootAuth/login',
    getFederatedLoginUrl: '/api/RootAuth/getFederatedLoginUrl'
};

export class RootAuthService {
    public static login(loginModel: RootLoginModel, config: AxiosRequestConfig = {}): Promise<ValidateAuthorizationCodeResponseModel> {
        config = {
            ...config,
        };
        const _data = loginModel;
        return axios.post<ValidateAuthorizationCodeResponseModel>(RootAuthUrls.login, _data, config).then(x => x.data);
    }
    
    public static getFederatedLoginUrl(config: AxiosRequestConfig = {}): Promise<string> {
        config = {
            ...config,
        };
        return axios.get<string>(RootAuthUrls.getFederatedLoginUrl, config).then(x => x.data);
    }
    
}