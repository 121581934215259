<template>
	<div v-if="!verifyPasswordResponseModel" class="login">
		<v-row>
			<v-col cols="12">
				<div class="mt-3 mb-3">
					{{ $t($tl.passwordMessages.checkEmail) }}
				</div>
				<vops-form @submit="verifyTemporaryPassword()">
					<v-text-field
						:label="$t($tl.email)"
						light
						v-model="email"
						readonly="readonly"/>
					<v-text-field
						:label="$t($tl.temporaryPassword)"
						light v-model="temporaryPassword"
						type="password"
						validate-on-blur
						v-focus
						:error-messages="passwordErrors"
						:rules="[$rules.required]"
						@blur="clearServerErrors()"
						@input="clearServerErrors()"/>
					<v-btn dark block color="#4146E0"
						   type="submit"
						   :loading="isLoading">
						{{ $t($tl.verifyTemporaryPassword) }}
					</v-btn>
				</vops-form>
			</v-col>
		</v-row></div>
	<div v-else class="login">
		<reset-password  :verify-temporary-password-response-model="verifyPasswordResponseModel"
						:user-email="email"/>
	</div>
</template>
<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import CenterView from "@/components/CenterView.vue";
import {VerifyTemporaryPasswordModel, VerifyTemporaryPasswordResponseModel} from "@/models/generated";
import {AuthService, PasswordService} from "@/services/generated";
import ResetPassword from "@/components/ResetPassword.vue";

@Component({
	components: {
		ResetPassword,
		CenterView
	}
})
export default class TemporaryPassword extends Vue {
	email: string = "";
	temporaryPassword: string = "";
	isAuthenticated: boolean = false;
	isInvalidTempPassword: boolean = false;
	isLoading: boolean = false;
	attemptsExceeded: boolean = false;
	verifyPasswordResponseModel: VerifyTemporaryPasswordResponseModel = null;

	async mounted() {
		this.email = this.$route.query.email as string;
		this.isAuthenticated = await AuthService.isAuthenticated();

		if (this.isAuthenticated)
			await this.$router.push({name: 'email'});
	}

	get passwordErrors() {
		let errorMessage = [];
		if (this.isInvalidTempPassword) {
			errorMessage = [this.$t(this.$tl.passwordMessages.invalidTemp)];
		} else if (this.attemptsExceeded) {
			errorMessage = [this.$t(this.$tl.passwordMessages.passwordAttemptsReachedMessage)];
		}
		return errorMessage;
	}

	clearServerErrors() {
		this.attemptsExceeded = false;
		this.isInvalidTempPassword = false;
	}

	async verifyTemporaryPassword() {
		this.isLoading = true;

		try {
			const verifyPasswordModel: VerifyTemporaryPasswordModel = {
				temporaryPassword: this.temporaryPassword.replace(" ", ""),
				email: this.email
			}
			this.verifyPasswordResponseModel = await PasswordService.verifyTemporaryPassword(verifyPasswordModel);
		} catch (e: any) {
			if (e.data.attemptsExceeded) {
				this.attemptsExceeded = true;
			} else {
				this.isInvalidTempPassword = true;
			}
		} finally {
			this.isLoading = false;
		}
	}
}
</script>
<style lang="scss">
.temporaryPassword {
	animation-name: slide-in;
	animation-duration: .5s;
}
</style>