import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { LangPath, genLangPaths } from '@eventbooking/vops-ui/language';
import Lang from '../i18n/lang';
import enLang from '@/config/i18n/translations/en';

Vue.use(VueI18n);

const defaultLangTag = 'en';

const i18n = new VueI18n({
	locale: defaultLangTag,
	fallbackLocale: defaultLangTag,
	messages: {
		en: enLang
	}
});

const loadedLanguages = [defaultLangTag]; // our default language that is preloaded 

export async function loadLanguageAsync (lang: string) {
	lang = lang || defaultLangTag;

	if (i18n.locale === lang)
		return;
	
	if (!loadedLanguages.includes(lang)) {
		const msgs = await import(/* webpackChunkName: "lang-[request]" */ `@/config/i18n/translations/${lang}.ts`);
		i18n.setLocaleMessage(lang, msgs.default);
		loadedLanguages.push(lang);
	}

	i18n.locale = lang;
}

export default i18n;

declare module 'vue/types/vue' {
	export interface Vue {
		$tl: LangPath<Lang>
	}
}

export const $lang = genLangPaths(enLang);
Vue.prototype.$tl = $lang;
