import axios, { AxiosRequestConfig } from 'axios';
import { UserLoginInfoModel } from '../../models/generated/userLoginInfoModel';
import { LoginResponseModel } from '../../models/generated/loginResponseModel';
import { GetUserLoginModel } from '../../models/generated/getUserLoginModel';
import { SendTempPasswordModel } from '../../models/generated/sendTempPasswordModel';
import { LoginModel } from '../../models/generated/loginModel';
import { MfaChallengeModel } from '../../models/generated/mfaChallengeModel';

export const AuthUrls = {
    getUserLogin: '/api/Auth/getUserLogin',
    resendEmail: '/api/Auth/resendEmail',
    login: '/api/Auth/login',
    respondToMfaChallenge: '/api/Auth/respondToMfaChallenge',
    loginWithToken: '/api/Auth/loginWithToken',
    isAuthenticated: '/api/Auth/isAuthenticated',
    logout: '/api/Auth/logout',
    getLandingPage: '/api/Auth/getLandingPage',
    impersonate: '/api/Auth/impersonate',
    deimpersonate: '/api/Auth/deimpersonate'
};

export class AuthService {
    public static getUserLogin(model: GetUserLoginModel, config: AxiosRequestConfig = {}): Promise<UserLoginInfoModel> {
        config = {
            ...config,
        };
        const _data = model;
        return axios.post<UserLoginInfoModel>(AuthUrls.getUserLogin, _data, config).then(x => x.data);
    }
    
    public static resendEmail(model: SendTempPasswordModel, config: AxiosRequestConfig = {}) {
        config = {
            ...config,
        };
        const _data = model;
        return axios.post(AuthUrls.resendEmail, _data, config).then(x => x.data);
    }
    
    public static login(loginModel: LoginModel, config: AxiosRequestConfig = {}): Promise<LoginResponseModel> {
        config = {
            ...config,
        };
        const _data = loginModel;
        return axios.post<LoginResponseModel>(AuthUrls.login, _data, config).then(x => x.data);
    }
    
    public static respondToMfaChallenge(model: MfaChallengeModel, config: AxiosRequestConfig = {}): Promise<LoginResponseModel> {
        config = {
            ...config,
        };
        const _data = model;
        return axios.post<LoginResponseModel>(AuthUrls.respondToMfaChallenge, _data, config).then(x => x.data);
    }
    
    public static loginWithToken(token: string, config: AxiosRequestConfig = {}): Promise<ArrayBuffer> {
        config = {
            responseType: 'arraybuffer',
            ...config,
            params: {
                token
            }
        };
        return axios.get<ArrayBuffer>(AuthUrls.loginWithToken, config).then(x => x.data);
    }
    
    public static isAuthenticated(config: AxiosRequestConfig = {}): Promise<boolean> {
        config = {
            ...config,
        };
        return axios.get<boolean>(AuthUrls.isAuthenticated, config).then(x => x.data);
    }
    
    public static logout(config: AxiosRequestConfig = {}) {
        config = {
            ...config,
        };
        return axios.post(AuthUrls.logout, config).then(x => x.data);
    }
    
    public static getLandingPage(config: AxiosRequestConfig = {}): Promise<string> {
        config = {
            ...config,
        };
        return axios.get<string>(AuthUrls.getLandingPage, config).then(x => x.data);
    }
    
    public static impersonate(userId: string, config: AxiosRequestConfig = {}): Promise<string> {
        config = {
            ...config,
            params: {
                userId
            }
        };
        return axios.get<string>(AuthUrls.impersonate, config).then(x => x.data);
    }
    
    public static deimpersonate(config: AxiosRequestConfig = {}): Promise<string> {
        config = {
            ...config,
        };
        return axios.get<string>(AuthUrls.deimpersonate, config).then(x => x.data);
    }
    
}