import { loadLanguageAsync } from '../plugins/i18n';
import { SystemService } from '@/services/generated';

export default class LocalizationConfig {
	static async bootstrap() {
		const supportedLanguages = await SystemService.getSupportedLanguages();
		const lang = supportedLanguages.map(x => x.tag).find(x => navigator.language.startsWith(x));
		await loadLanguageAsync(lang);
	}
}
