import Lang from '../lang';

const en: Lang = {
	"accountActivated": "Account activated",
	"activateAccount": "Activate Account",
	"confirmPassword": "Confirm Password",
	"passwordLengthValue": "The password must be at least {value} characters long",
	"email": "Email",
	"validating": "Validating user information...",
	"contactAdmin": "Please contact your administrator.",
	"verifyTemporaryPassword": "Verify",
	"accountNotFoundSSO": "Your account is either inactive or does not exist in Momentus Elite.",
	"validationError": "There was an issue validating your identity.",
	"emailMessages": {
		"accountInactive": "This account is inactive",
		"invalid": "Invalid email",
		"noUser": "No user found for this email address",
		"required": "Email is required"
	},
	"forgotPassword": "Forgot Password?",
	"continue": "Continue",
	"temporaryPassword": "Temporary Password",
	"verificationCode": "Verification Code",
	"newPassword": "New Password",
	"password": "Password",
	"passwordHasBeenReset": "Your password has been reset",
	"passwordMessages": {
		"checkJunkFolder": "If you don't see the email, check other places it might be, like your junk, spam, social, or other folders.",
		"checkEmail": "An email has been sent to the following address containing a temporary password. Please enter this temporary password.",
		"forgotPasswordCheckEmail": "If there is a Momentus Elite account associated with your email, you will receive a verification code in your inbox to reset your password",
		"dontMatch": "Passwords do not match",
		"emailSentTo": "We've sent an email to:",
		"invalid": "Invalid email or password combination",
		"passwordAttemptsReachedMessage" : "Your account has been locked due to too many attempts. Please wait 15 minutes then try again.",
		"invalidTemp": "Invalid temporary password",
		"invalidVerificationCode": "Invalid verification code",
		"invalidUsername": "Invalid username",
		"minLength": "Minimum length: {value}",
		"specialCharactersRequired": "At least one special character required",
		"numbersRequired": "At least one number required",
		"lowercaseRequired": "At least one lower case letter required",
		"uppercaseRequired": "At least one upper case letter required",
		"whitespaceDetected" : "Password cannot have whitespace",
		"sessionExpired": "Session has expired. Please refresh the page.",
		"problemActivating": "There was a problem activating your account. Please contact your system administrator.",
		"problemResetting": "There was a problem resetting your password. If this issue continues, please contact your administrator.",
		"required": "Password is required",
		"useLinkFromEmail": "Please make sure you're using the link from the latest email",
		"resetSuccess": "Your password was successfully reset"
	},
	"redirecting": "Redirecting",
	"resetPassword": "Reset",
	"sendEmail": "Send Email",
	"signIn": "Sign In",
	"multiFactorAuthentication": {
		"totp": "Please enter your authentication code",
		"totpCode": "Please enter the 6 digit code found on your Authenticator app",
		"enterCode": "Please enter code",
		"resend": "resend code",
		"invalidMfaCode": "Invalid Code",
		"required": "Code is required"
	},
	"support": {
		"supportSignIn": "Support Sign In"
	}
};

export default en;