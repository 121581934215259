<template>
	<div v-if="isInitialized">
		<v-row class="login" elevation-2 >
			<template v-if="!isUnauthorized && !isError">
				<v-col cols="12">
					<div class="text-center validating-login-text">{{ $t($tl.validating) }}</div>
				</v-col>
				<v-col cols="12">
					<div class="text-center">
						<v-progress-circular width="4" size="48" indeterminate/>
					</div>
				</v-col>
			</template>
			<template v-else>
				<v-col v-if="isUnauthorized" cols="12">
					<span>Unauthorized</span>
				</v-col>
				<v-col v-if="isError" cols="12">
					<span>{{ $t($tl.validationError) }}</span>
					<br/>
					<br/>
					<i v-if="errorDescription">{{ errorDescription }}</i>
				</v-col>
			</template>
		</v-row>
	</div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import CenterView from "@/components/CenterView.vue";
import {RootAuthService} from "@/services/generated";

@Component({components: {CenterView}})
export default class RootAccessValidate extends Vue {
	isInitialized: boolean = false;
	authorizationCode: string = "";
	isUnauthorized: boolean = false;
	isError: boolean = false;
	errorDescription: string = "";


	async mounted() {
		this.authorizationCode = this.$route.query.code as string;
		if (!this.authorizationCode) {
			this.isError = true;
			this.isInitialized = true;
			this.errorDescription = this.$route.query.error_description as string;
			return;
		}

		try {
			const responseModel = await RootAuthService.login({authorizationCode: this.authorizationCode});
			window.location.assign(responseModel.returnUrl);
		} catch (e: any) {
			if (e.status === 401) {
				this.isUnauthorized = true;
			} else {
				this.isError = true;
			}
		} finally {
			this.isInitialized = true;
		}
	}
}
</script>

<style scoped>

</style>