import axios, { AxiosRequestConfig } from 'axios';
import { CultureInfoModel } from '../../models/generated/cultureInfoModel';
import { SupportedLanguageModel } from '../../models/generated/supportedLanguageModel';

export const SystemUrls = {
    getTimeZones: '/api/System/getTimeZones',
    getCultures: '/api/System/getCultures',
    getSupportedLanguages: '/api/System/getSupportedLanguages'
};

export class SystemService {
    public static getTimeZones(config: AxiosRequestConfig = {}): Promise<string[]> {
        config = {
            ...config,
        };
        return axios.get<string[]>(SystemUrls.getTimeZones, config).then(x => x.data);
    }
    
    public static getCultures(config: AxiosRequestConfig = {}): Promise<CultureInfoModel[]> {
        config = {
            ...config,
        };
        return axios.get<CultureInfoModel[]>(SystemUrls.getCultures, config).then(x => x.data);
    }
    
    public static getSupportedLanguages(config: AxiosRequestConfig = {}): Promise<SupportedLanguageModel[]> {
        config = {
            ...config,
        };
        return axios.get<SupportedLanguageModel[]>(SystemUrls.getSupportedLanguages, config).then(x => x.data);
    }
    
}