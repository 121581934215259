import Vue from 'vue';
import vopsui from '@/config/plugins/vops-ui';
import { vuetify } from "@eventbooking/vops-ui/plugin";
import i18n from '@/config/plugins/i18n';
import AxiosConfig from '@/config/axios/axiosConfig';
import LocalizationConfig from '@/config/i18n/localizationConfig';
import App from '@/App.vue';
import router from '@/router';

export default class AppBootstrapper {
	public static async bootstrap() {
		Vue.config.productionTip = false;

		AxiosConfig.bootstrap();
		await LocalizationConfig.bootstrap();

		new Vue({
			router,
			i18n,
			vuetify,
			vopsui,
			render: (h) => h(App),
		}).$mount('#app');
	}
}