<template>
	<div class="moY-login" :class="{'mLoginAnimate': animateSlider}">
		<div class="moP-login">
			<div class="moB-login">
				<tech-logo light width="50%" class="momentus-tech-logo"/>
				<v-container fill-height text-xs-center class="CenterView-Background">
					<v-row class="justify-center align-center CenterView-login" >
						<v-sheet color="transparent" :width="width" light>
							<v-card class="pa-3 mo-neutral login" >
								<v-row class="email-login" elevation-2>
									<v-card-title>
										<v-col cols="12" class="text-center">
											<vops-logo width="195"/>
										</v-col>
									</v-card-title>
									<v-col cols="12" class="text-center">
										<v-card-text class="pa-2">
											<router-view />
										</v-card-text>
									</v-col>
								</v-row>
							</v-card>
						</v-sheet>
					</v-row>
				</v-container>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";

@Component
export default class CenterView extends Vue {
	@Prop({ type: Number, default: 270 }) width: number;
	animateSlider: boolean = false;
	
	created() {
		this.animateSlider = this.$route.name === "login";
	}
	
}
</script>
<style lang="scss">

.CenterView-Background{
  background-image: url("../assets/me-login.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-attachment: fixed;
  background-position-x: -120px;
}

.CenterView-login {
  max-width:85% !important;
}

div.mLoginAnimate {
  animation: slideIn 1s forwards;
}

@keyframes slideIn {
  0% {
	transform: translateX(-1200px);
  }
  100% {
	transform: translateX(0);
  }
}
.moB-login {
  clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
  width: 94%;
  height:100%;
  background: #1C1E61;
  display: flex;
  justify-content: center;
  align-items: center;
}
.moP-login {
  clip-path: polygon(0 0, 100% 0, 73% 100%, 0% 100%);
  width: 99%;
  height:100%;
  background: linear-gradient( to right, #4146e0, #4146e0, #4146e0, #b166e3);
}
.moY-login {
  top:0%;
  position:relative;
  clip-path: polygon(0 0, 100% 0, 73.5% 100%, 0% 100%);
  width: 57%;
  height:100%;
  background: transparent;

}
.momentus-tech-logo {
  top:50px;
  left:50px;
  position: absolute;
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: .85; }
}

.login {
  animation: fadeIn .5s;
}

@media only screen and (max-width: 768px) {
  .moY-login, .moB-login, .moP-login {
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
	width:100%;
	height:100%;
	background: #1C1E61;
  }
  .CenterView-login {
	max-width:120%!important;
	width: 120%;
  }
  .momentus-tech-logo {
	width: 90%;
	left:20px;
  }
  div.mLoginAnimate {
	top:0%;
	position:relative;
	animation: none;
  }
}
</style>