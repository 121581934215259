<template>
	<div>
		<template v-if="isEnteringEmail">
			<vops-form @submit="login()" class="mb-2">
				<v-text-field class="autofill"
							  :label="$t($tl.email)"
							  v-model="email"
							  light
							  v-focus
							  validate-on-blur
							  :error-messages="emailErrors"
							  :rules="[$rules.required, $rules.email]"
							  @input="clearServerErrors()"/>
				<v-btn dark block color="#4146E0" type="submit" :loading="isLoading" class="mt-2">{{ $t($tl.continue) }}</v-btn>
			</vops-form>
			<div class="mt-3" v-if="reset">
				{{ $t($tl.passwordMessages.resetSuccess) }}
			</div>
		</template>
		<template v-else>
			<password-submission :email="email" @go-back="isEnteringEmail = true"/>
		</template>
	</div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import CenterView from './CenterView.vue';
import PasswordSubmission from './PasswordSubmission.vue';
import {GetUserLoginModel, SendTempPasswordModel, UserLoginInfoModel} from "@/models/generated";
import {AuthService} from "@/services/generated";
import AuthRedirectService from "@/services/authRedirectService";

@Component({
	components: {
		CenterView, PasswordSubmission
	}
})
export default class EmailLogin extends Vue {
	email: string = "";
	isError: boolean = false;
	isLoading: boolean = false;
	isEnteringEmail: boolean = true;
	reset: string = null;

	mounted() {
		this.isLoading = false;
		this.reset = this.$route.query.reset as string;
		if (this.$route.query.email !== null) {
			this.email = this.$route.query.email as string;
		}
	}

	get emailErrors() {
		return this.isError ? [this.$t(this.$tl.emailMessages.invalid)] : [];
	}

	clearServerErrors() {
		this.isError = false;
	}

	async login() {
		this.isLoading = true;

		const isAuthenticated = await AuthRedirectService.RedirectIfAuthenticated();
		
		if (isAuthenticated)
			return;

		this.reset = "";
		try {
			const getUserLoginModel: GetUserLoginModel = {email: this.email};
			const userLoginInfo: UserLoginInfoModel = await AuthService.getUserLogin(getUserLoginModel);
			if (userLoginInfo.isSsoRequired) {
				window.location.assign(userLoginInfo.loginUrl);
				return;
			}

			if (userLoginInfo.isVerified) {
				this.isEnteringEmail = false;
			} else {
				const request: SendTempPasswordModel = {email: this.email};
				await AuthService.resendEmail(request);
				await this.$router.push({path: `/resetpassword?email=${encodeURIComponent(this.email)}`});
			}

		} catch {
			this.isError = true;
		}
		this.isLoading = false;
	}
}
</script>
