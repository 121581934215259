import axios, { AxiosRequestConfig } from 'axios';
import { VerifyTemporaryPasswordResponseModel } from '../../models/generated/verifyTemporaryPasswordResponseModel';
import { SetNewPasswordResponseModel } from '../../models/generated/setNewPasswordResponseModel';
import { ForgotPasswordResponseModel } from '../../models/generated/forgotPasswordResponseModel';
import { ResetForgottenPasswordResponseModel } from '../../models/generated/resetForgottenPasswordResponseModel';
import { VerifyTemporaryPasswordModel } from '../../models/generated/verifyTemporaryPasswordModel';
import { SetNewPasswordModel } from '../../models/generated/setNewPasswordModel';
import { ForgotPasswordModel } from '../../models/generated/forgotPasswordModel';
import { ResetForgottenPasswordModel } from '../../models/generated/resetForgottenPasswordModel';

export const PasswordUrls = {
    verifyTemporaryPassword: '/api/Password/verifyTemporaryPassword',
    setNewPassword: '/api/Password/setNewPassword',
    forgotPassword: '/api/Password/forgotPassword',
    resetForgottenPassword: '/api/Password/resetForgottenPassword'
};

export class PasswordService {
    public static verifyTemporaryPassword(model: VerifyTemporaryPasswordModel, config: AxiosRequestConfig = {}): Promise<VerifyTemporaryPasswordResponseModel> {
        config = {
            ...config,
        };
        const _data = model;
        return axios.post<VerifyTemporaryPasswordResponseModel>(PasswordUrls.verifyTemporaryPassword, _data, config).then(x => x.data);
    }
    
    public static setNewPassword(model: SetNewPasswordModel, config: AxiosRequestConfig = {}): Promise<SetNewPasswordResponseModel> {
        config = {
            ...config,
        };
        const _data = model;
        return axios.post<SetNewPasswordResponseModel>(PasswordUrls.setNewPassword, _data, config).then(x => x.data);
    }
    
    public static forgotPassword(model: ForgotPasswordModel, config: AxiosRequestConfig = {}): Promise<ForgotPasswordResponseModel> {
        config = {
            ...config,
        };
        const _data = model;
        return axios.post<ForgotPasswordResponseModel>(PasswordUrls.forgotPassword, _data, config).then(x => x.data);
    }
    
    public static resetForgottenPassword(model: ResetForgottenPasswordModel, config: AxiosRequestConfig = {}): Promise<ResetForgottenPasswordResponseModel> {
        config = {
            ...config,
        };
        const _data = model;
        return axios.post<ResetForgottenPasswordResponseModel>(PasswordUrls.resetForgottenPassword, _data, config).then(x => x.data);
    }
    
}