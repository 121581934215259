<template>
	<div v-if="isInitialized">
        <v-row class="login" elevation-2 >
            <template v-if="!isForbid && !isUnauthorized && !isError">
                <v-col cols="12">
                    <div class="text-center validating-login-text">{{$t($tl.validating)}}</div>
                </v-col>
                <v-col cols="12">
                    <div class="text-center"><v-progress-circular width="4" size="48" indeterminate /></div>
                </v-col>
            </template>
            <template v-else>
                <v-col v-if="isForbid" cols="12">
                    <span>{{$t($tl.accountNotFoundSSO)}}&nbsp{{$t($tl.contactAdmin)}}</span>
                </v-col>
                <v-col v-if="isUnauthorized" cols="12">
                    <span>{{$t($tl.validationError)}}&nbsp{{$t($tl.contactAdmin)}}</span>
                </v-col>
                <v-col v-if="isError" cols="12">
                    <span>{{$t($tl.validationError)}}</span>
                    <br />
	                <br />
	                <i v-if="errorDescription">{{errorDescription}}</i>
                </v-col>
                <v-btn class="sign-in-button" block dark color="#4146E0" @click="signIn" type="submit">{{$t($tl.signIn)}}</v-btn>
            </template>
        </v-row>
	</div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator'
import CenterView from "@/components/CenterView.vue";
import {SsoAuthService} from "@/services/generated";
import {ValidateAuthorizationCodeModel, ValidateAuthorizationCodeResponseModel} from "@/models/generated";

@Component({
    components: {CenterView}
})
export default class ValidateSso extends Vue {
    
    isInitialized: boolean = false;
    accountId: string = "";
    authorizationCode: string = "";
    isUnauthorized: boolean = false;
    isForbid: boolean = false;
    isError: boolean = false;
    errorDescription: string = "";
    
    @Watch('$route.params.id', {immediate: true})
    async onRouteParamIdUpdated(value) {
        this.accountId = value;
    }

    async signIn() {
	    await this.$router.push({name: 'email'});
    }
    
    async mounted() {
        this.authorizationCode = this.$route.query.code as string;
        if (!this.authorizationCode || this.authorizationCode === undefined) {
	        this.isError = true;
	        this.isInitialized = true;
	        this.errorDescription = this.$route.query.error_description as string;
        	return;
        }

        const validateAuthorizationCodeModel: ValidateAuthorizationCodeModel = {accountId: this.accountId, authorizationCode: this.authorizationCode};
        try
        {
            const validateAuthorizationCodeResponseModel: ValidateAuthorizationCodeResponseModel = await SsoAuthService.validateAuthorizationCode(validateAuthorizationCodeModel);
            window.location.assign(validateAuthorizationCodeResponseModel.returnUrl);
        } catch (e: any) {
            if (e.status === 401) {
                this.isUnauthorized = true;
            } else if (e.status === 400) {
                this.isForbid = true;
            } else {
                this.isError = true;
            }
        } finally {
            this.isInitialized = true;
        }
    }
}
</script>

<style scoped>

.validating-login-text {
    font-size: 1.25rem;
}

.sign-in-button {
    margin-top: 1.5rem;
}

</style>