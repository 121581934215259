<template>
	<div class="login">
		<template v-if="!mfaRequired">
			<vops-form @submit="login()">
				<v-text-field
						:label="$t($tl.email)"
						light
						v-model="email"
						readonly="readonly"/>
				<v-text-field class="autofill"
							  :label="$t($tl.password)"
							  type="password"
							  light
							  ref="passwordField"
							  v-model="password"
							  :rules="[$rules.required, invalidPasswordError, passwordLimitError]"
							  @input="clearServerErrors"
							  @blur="clearServerErrors"
							  prepend-inner-icon="keyboard_backspace"
							  @click:prepend-inner="goBack"/>
				<v-btn class="mt-2" block color="#4146E0" dark type="submit" :loading="isLoading">
					{{ $t($tl.signIn) }}
				</v-btn>
			</vops-form>
			<v-btn color="blue-grey" text :to="{name: 'forgotpassword', params: {email}}">
				{{ $t($tl.forgotPassword) }}
			</v-btn>
		</template>
		<template v-else>
			<mfa-login :email="email" :session-id="sessionId"/>
		</template>
	</div>
</template>

<script lang="ts">
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {AuthenticationState, LoginModel, LoginResponseModel} from "@/models/generated";
import MfaLogin from "./MfaLogin.vue";
import {AuthService} from '@/services/generated';
import CenterView from './CenterView.vue';

@Component({
	components: {
		CenterView, MfaLogin
	}
})
export default class Login extends Vue {
	@Prop({type: String, required: true}) email: string;

	@Emit() goBack() {
	}

	password: string = null;
	returnUrl: string = null;
	isLoading: boolean = false;
	isError: boolean = false;
	isInitialized: boolean = false;
	mfaRequired: boolean = false;
	passwordAttemptsExceeded: boolean = false;
	sessionId: string = null;
	loginResponse: LoginResponseModel = null;

	get invalidPasswordError() {
		return !this.isError || this.$t(this.$tl.passwordMessages.invalid);
	}

	get passwordLimitError() {
		return !this.passwordAttemptsExceeded || this.$t(this.$tl.passwordMessages.passwordAttemptsReachedMessage);
	}

	async mounted() {
		this.isLoading = false;

		this.returnUrl = this.$route.params.returnUrl;
		this.isInitialized = true;
		(this.$refs.passwordField as HTMLElement).focus();
	}

	clearServerErrors() {
		this.isError = false;
		this.passwordAttemptsExceeded = false;
	}
	

	async login() {
		this.isError = false;
		this.isLoading = true;

		try {
			const model: LoginModel = {email: this.email, password: this.password};
			const loginResponseModel: LoginResponseModel = await AuthService.login(model);
			await this.determineLoginState(loginResponseModel);
		} catch {
			this.isError = true;
			this.isLoading = false;
		}
	}

	async determineLoginState(loginResponseModel: LoginResponseModel) {
		if (loginResponseModel.authenticationState == AuthenticationState.Authenticated) {
			window.location.assign(this.returnUrl || loginResponseModel.returnUrl);
		} else if (loginResponseModel.authenticationState == AuthenticationState.PasswordAttemptsExceeded) {
			this.passwordAttemptsExceeded = true;
			this.isLoading = false;
		} else if (loginResponseModel.authenticationState == AuthenticationState.NeedsPasswordReset) {
			await this.$router.push({path: '/resetpassword?email=' + encodeURIComponent(this.email)});
		} else if (loginResponseModel.authenticationState == AuthenticationState.TotpChallenge) {
			this.loginResponse = loginResponseModel;

			this.sessionId = loginResponseModel.sessionId;
			this.mfaRequired = true;
		}
	}

}
</script>