export const hasLowerCase = (str) => {
	return str.toUpperCase() !== str;
}

export const hasUpperCase = (str) => {
	return str.toLowerCase() !== str;
}

export const hasNumber = (str) => {
	return /\d/.test(str);
}

export const hasSpecialCharacters = (str) => {
	return /[~`!@#$%^&*+=\-\[\]\\';,/{}|":<>?()._]/g.test(str);
}

export const hasWhitespace = (str) => {
	return /\s/.test(str);
}